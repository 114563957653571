export enum Web3Platform {
  METAMASK = "metamask",
  WALLETCONNECT = "walletconnect",
  RABBYWALLET = "rabbywallet",
  PHANTOMWALLET = "phantomwallet",
  METAMASKDUMMY = "metamask-dummy",
  PHANTOMDUMMY = "phantom-dummy",
}

export const Web3PlatformNames: Record<Web3Platform, string> = {
  [Web3Platform.METAMASK]: "MetaMask",
  [Web3Platform.WALLETCONNECT]: "WalletConnect",
  [Web3Platform.RABBYWALLET]: "Rabby Wallet",
  [Web3Platform.PHANTOMWALLET]: "Phantom Wallet",
  [Web3Platform.PHANTOMDUMMY]: "Phantom Wallet",
  [Web3Platform.METAMASKDUMMY]: "MetaMask",
};
